import React, { Component } from 'react'
import Button from '../Components/Button'
import background from './backround-chevrons.png'

class Home extends Component {
  render() {    
    let blockOneJsx
    
    if (this.props.device === 'desktop') {
      blockOneJsx = (
        <div className="div-background-image" style={{ 
          background: `url(${background}) center center / 100% 100% no-repeat`,
        }}>
          <div className="overlay-text">
            <p>
              The <span className="text-strong">SensorRx Chronic Disease Management Platform</span> is a robust, EHR-integrated platform that utilizes end-to-end encryption and is HIPAA compliant, providing a rich platform for the user to input treatment-related information in a quick and succinct manner.  
            </p>
          </div>
        </div>
      )
    } else {
      blockOneJsx = (
        <div className="overlay-text">
            <p>
              The <span className="text-strong">SensorRx Chronic Disease Management Platform</span> is a robust, EHR-integrated platform that utilizes end-to-end encryption and is HIPAA compliant, providing a rich platform for the user to input treatment-related information in a quick and succinct manner.  
            </p>
          </div>
      )
    }

    let blockTwoJsx

    if (this.props.device === 'desktop') {
      blockTwoJsx = (
        <div className="div-no-image">
          <div className="overlay-text">
            <p className="text-strong">
              SensorRx provides EHR-niave, integrated, robust, flexible and scalable digital health solutions for chronic illness management for the spectrum of chronic illnesses and their large, often undertreated populations. Chronically ill patients frequently struggle to keep consistent track of number and quality of flares.  
            </p>
            <Button buttonText="Learn More" internalUrlPath="/about" type="big-button home-learn-more"/>
          </div>
        </div>
      )
    } else {
      blockTwoJsx = (
        <div className="div-no-image">
          <div className="overlay-text">
            <p className="text-strong">
              SensorRx provides EHR-naive, integrated, robust, flexible and scalable digital health solutions for chronic illness management for the spectrum of chronic illnesses and their large, often undertreated populations. Chronically ill patients frequently struggle to keep consistent track of number and quality of flares.
            </p>
            <Button buttonText="Learn More" internalUrlPath="/about" type="mobile-button"/>
          </div>
        </div>    
      )
    }

    const blockThreeJsx = (
      // TODO: format list with number icons
      <div className="div-list">
        <div className="list-text">
          <h4>Our backend system and individual apps are comprised of:</h4>
          <ol className="list">
            <li>A patient-facing frontend mobile application</li>
            <li>A physician-facing portal</li>
            <li>A predictive modeling tool based on a patient’s cumulative information</li>
          </ol>
          <Button buttonText="Learn More" internalUrlPath="/about" type="big-button home-learn-more"/>
        </div>
      </div>
    )

    
    return (
      <div className="home-page">
        {blockOneJsx}
        {blockTwoJsx}
        {blockThreeJsx}
      </div>
    )
  }
}

export default Home