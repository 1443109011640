import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import ProductPreview from './ProductPreview'
import { listAllProducts } from './products'


class ProductIndex extends Component {
  constructor(props) {
    super(props)

    this.state = {
      products: []
    }
  }

  componentDidMount() {
    this.setState({
      products: listAllProducts()
    })
  }
  
  render() {
    const headerBlockJsx = (
      <div className="div-no-image">
        <div className="overlay-text">
          <h3><span className="white">SensorRx</span> Products</h3>
          <p>SensorRx has developed applications for clinical, research and consumer use in multiple therapeutic areas. Below is a description of some of our offerings: </p>
        </div>
        
      </div>
    )
    
    const productIndexJsx = this.state.products.map(
      product => <ProductPreview key={product.id} product={product} device={this.props.device}/>
    )
    
    return (
      <div className="products-page">
        {headerBlockJsx}
        <div className="desktop-wrapper">
          <div className="product-index">{productIndexJsx}</div>
        </div>
      </div>
    )
  }
}

export default withRouter(ProductIndex)
