import React, { Component } from 'react'
import NewsPreview from './NewsPreview'
import { listAllNews } from './news'

class NewsIndex extends Component {
  constructor(props) {
    super(props)

    this.state = {
      newsItems: []
    }
  }

  componentDidMount() {
    this.setState({
      newsItems: listAllNews()
    })
  }

  render() {
    const headerBlockJsx = (
      <div className="div-no-image">
        <div className="header-text">
          <h3><span className="white">SensorRx</span> News</h3>
        </div>
      </div>
    )
    
    const newsIndexJsx = this.state.newsItems.map(
      newsItem => <NewsPreview key={newsItem.date} newsItem={newsItem} /> 
    )
    
    return (
      <div>
        {headerBlockJsx}
        <div className="news-section">  
          {newsIndexJsx}
        </div>
      </div>
        
    )  
  }
}

export default NewsIndex