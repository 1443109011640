import React, { Component } from 'react'
import Button from '../Components/Button'
import knifeGraphic from './knife-colorized-nobg.png'

class About extends Component {
  render() {
    const titleJsx = (
      <div className="div-no-image">
        <h3><span className="white">SensorRx</span> Brings the Illness into the Clinic</h3>
      </div>
    )
    
    const graphicBlockJsx = (
      <div className="div-graphic">
        <img src={knifeGraphic} alt="SensorRx is a Swiss Army Knife of Tools" title="SensorRx Tools Graphic"></img>
        <p>
          Like a Swiss Army knife, the SensorRx frontend can be configured to fit any therapeutic area that requires on-going monitoring by their physician, like Asthma/COPD, IBD, Cancer, Depression, Migraine, CAD and more. 
        </p>
        
      </div>
    )

    const paragraphJsx = (
      <div className="div-no-image">
        <p>
          The majority of patients’ illness occurs while the physician is absent. <span className="white">SensorRx</span> enables the patient to bring the acute flares of these chronic illnesses into the clinic, by enabling the patient to succinctly document flares, information which physicians can seamlessly access via the integrated mobile applications. <span className="white">SensorRx</span> apps allow patients to conveniently track symptoms, triggers, medications, weather and other important details of the illness that assist the physician in clinical decisions.
          <Button buttonText="View Our Products" internalUrlPath="/products" type="products-link big-button" />
        </p>


      </div>
    )

    return (
      <div className="about-page">
        {titleJsx}
        {graphicBlockJsx}
        {paragraphJsx}
      </div>
      
    )
  }
}

export default About