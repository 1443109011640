import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'

class Nav extends Component {
  showSettings (event) {
    event.preventDefault()
  }
  
  render() {
    let navJsx

    if (this.props.device === 'desktop') {
      navJsx = (
        <nav>
          <ul>
            <li><NavLink to="/home" id="home" activeClassName="selected">Home</NavLink></li>
            <li><NavLink to="/about" id="about" activeClassName="selected">About</NavLink></li>
            <li><NavLink to="/team" id="team" activeClassName="selected">SensorRx Team</NavLink></li>
            <li><NavLink to="/board" id="board" activeClassName="selected">Board of Directors</NavLink></li>
            <li><NavLink to="/scientific-advisors" id="scientific-advisors" activeClassName="selected">Scientific Advisors</NavLink></li>
            <li><NavLink to="/news" id="news" activeClassName="selected">News</NavLink></li>
            <li><NavLink to="/products" id="products" activeClassName="selected">Products</NavLink></li>
            <li><NavLink to="/partners" id="partners" activeClassName="selected">Partners</NavLink></li>
            <li><NavLink to="/contact" id="contact" activeClassName="selected">Contact</NavLink></li>
          </ul>
        </nav>
      )
    } else {
      navJsx = (
        <Menu>
          <a href="/home" id="home">Home</a>
          <a href="/about" id="about">About</a>
          <a href="/team" id="team">SensorRx Team</a>
          <a href="/board" id="board">Board of Directors</a>
          <a href="/scientific-advisors" id="scientific-advisors">Scientific Advisors</a>
          <a href="/news" id="news">News</a>
          <a href="/products" id="products">Products</a>
          <a href="/partners" id="partners">Partners</a>
          <a href="/contact" id="contact">Contact</a>
        </Menu>
      )
    }
    
    return(
      <div className="navbar">{navJsx}</div>
    )
  }
}

export default Nav