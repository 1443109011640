import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { formatFullName, formatProfileTitles, profilePhotoPath, profilePhotoAlt } from '../helpers'
import Button from '../Components/Button'

class ProfilePreview extends Component {
  // TODO: format all photos to match in size
  render() {
    const { id, firstName, lastName, postnomials, srxTitles, outsideTitles, isOnSrxTeam } = this.props.person 
    const fullName = formatFullName(firstName, lastName, postnomials)
    const titles = formatProfileTitles(srxTitles, outsideTitles, isOnSrxTeam)
    const internalUrlPath = `/profiles/${id}`
    
    let buttonJsx

    if (this.props.returnLink) {
      buttonJsx = <Button type="view-profile" buttonText="View Profile" internalUrlPath={internalUrlPath} returnLink={this.props.returnLink}/>
    } else {
      buttonJsx = '' 
    }

    const profilePreviewJsx = (
      <Fragment>
        <img className="profile-preview-image" src={profilePhotoPath(id)} alt={profilePhotoAlt(fullName)} title={fullName}></img>
        <div className="profile-preview-text">
          <h2 className="profile-name">{fullName}</h2>
          {titles}
          {buttonJsx}
        </div>
        
      </Fragment>
    )
    
    return <div className="profile-preview profile-preview-background">{profilePreviewJsx}</div>
  }
}

export default withRouter(ProfilePreview)