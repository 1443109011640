import React, { Component, Fragment } from 'react'
import Nav from './Nav.js'

class Header extends Component {
  render() {
    let logoPath
    if (this.props.device === 'desktop') {
      logoPath = process.env.PUBLIC_URL + `/logos/sensorrx-logo-large.png`
    } else {
      logoPath = process.env.PUBLIC_URL + `/logos/sensorrx-logo-mobile.png`
    }
    return (
      <Fragment>
        <div className="header-logo-area">
          <img className="header-logo" src={logoPath} alt="SensorRx Logo"></img>
        </div>
        <Nav device={this.props.device}/>
      </Fragment>
    )
  }
}

export default Header