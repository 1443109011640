import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

class Button extends Component {
  
  render() {
    const { buttonText, url, internalUrlPath, type, returnLink } = this.props
    
    let buttonJsx

    if (internalUrlPath) {
      buttonJsx = (
        <Link to={internalUrlPath}>
          <button className={type} returnLink={returnLink}>{buttonText}</button> 
        </Link>
      )
    }

    if (!internalUrlPath) {
      buttonJsx = (
        <a href={url}>
          <button className={type}>{buttonText}</button>
        </a>
      )
    }

    return (
      <div className="button-container">
        {buttonJsx}
      </div>
    )
  }
}

export default withRouter(Button)