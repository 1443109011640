import React, { Component } from 'react'
import { withRouter } from 'react-router-dom' 
import { teamIndex } from './people'
import ProfilePreview from './ProfilePreview'

// TODO: Show ONLY Srx titles on TeamIndex page (remove outside titles)
class TeamIndex extends Component {
  constructor(props) {
    super(props)

    this.state = {
      people: []
    }
  }

  componentDidMount() {
    this.setState({
      people: teamIndex()
    })
  }

  render() {
    const teamIndexJsx = this.state.people.map(
      person => <ProfilePreview key={person.id} person={person} returnLink="true"/> 
    )
    
    return (
      <div className="page-wrapper">
        <div className="profile-index">{teamIndexJsx}</div>
      </div>
    )
  }
}

export default withRouter(TeamIndex)