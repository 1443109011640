import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { profileShow } from './people'
import { formatFullName, formatProfileText, formatProfileTitles, profilePhotoPath, profilePhotoAlt } from '../helpers'
import Button from '../Components/Button'

// TODO: render ProfileShow as a MODAL rather than its own page (will make routing simpler)
class ProfileShow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      person: null
    }
  }

  componentDidMount() {
    this.setState({
      person: profileShow(this.props.id),
    })
  }
  
  render () {
    if (!this.state.person) {
      return <p>"Loading..."</p>
    } 
    
    // TODO: add formatting for outsideTitles
    if (this.state.person) {
      const { id, firstName, lastName, postnomials, isOnSrxTeam, srxTitles, isScientificAdvisor, outsideTitles, profileText} = this.state.person 
      const fullName = formatFullName(firstName, lastName, postnomials)
      const formattedProfileText = formatProfileText(profileText)
      const formattedProfileTitles = formatProfileTitles(srxTitles, outsideTitles, isOnSrxTeam)
      
      let internalUrlPath

      if (isOnSrxTeam) {
        internalUrlPath = "/team"
      }

      if (isScientificAdvisor) {
        internalUrlPath = "/scientific-advisors"
      }

      // TODO: make link return to correct team page (team/board/advisors)
      const profileJsx = (
        <div className="profile-background">
          <div className="profile-image-section">
            <img 
              className="profile-image" 
              src={profilePhotoPath(id)} 
              alt={profilePhotoAlt(fullName)} 
              title={fullName}>
            </img>
          </div>
          <Button buttonText="Return" internalUrlPath={internalUrlPath} type="return-button"/>
          <div className="profile-text-section">
            <div className="profile-title-section">
              <h2 className="profile-name">{fullName}</h2>
              {formattedProfileTitles}
            </div>
            {formattedProfileText}
          </div>
        </div>
        )
      
      return (
        <div className="page-wrapper profile-show">{profileJsx}</div>
      )
    }
  }
}

export default withRouter(ProfileShow)