const newsItems = [
  {
    date: '2019-10-01',
    title: 'UCSF Health Hub Announces Finalists for UCSF Digital Health Awards',
    sourceName: 'UCSF Digital Health Awards 2019',
    url: 'https://www.ucsfhealthhub.com/ucsf-digital-health-awards-press-release-oct-1-2019',
  },
  {
    date: '2019-01-22',
    title: 'SensorRx Re-Introduces Migraine Coach™ App for Android and iOS',
    sourceName: 'Press Release',
    url: 'https://www.prnewswire.com/news-releases/sensorrx-re-introduces-migraine-coach-app-for-android-and-ios-300781366.html',
  },
  {
    date: '2018-09-06',
    title: 'Migraine Tech Tools',
    sourceName: 'Ladue News',
    url: 'https://www.prnewswire.com/news-releases/sensorrx-re-introduces-migraine-coach-app-for-android-and-ios-300781366.html9',
  },
  {
    date: '2018-07-12',
    title: 'Utilizing Mobile Health Technology to Improve Migraine Care',
    sourceName: 'AJMC - The American Journal of Managed Care',
    url: 'https://www.ajmc.com/view/utilizing-mobile-health-technology-to-improve-migraine-care',
  },
  {
    date: '2018-07-09',
    title: 'Migraine apps unite in SensorRXs acquisition of Welltodo',
    sourceName: 'MobiHealthNews',
    url: 'https://www.mobihealthnews.com/content/migraine-apps-unite-sensorrxs-acquisition-welltodo',
  },
  {
    date: '2018-06-29',
    title: 'SensorRx To Acquire Groundbreaking Mobile Health App Migraine Coach™',
    sourceName: 'Press Release',
    url: 'https://www.prnewswire.com/news-releases/sensorrx-to-acquire-groundbreaking-mobile-health-app-migraine-coach-300674816.html',
  },
  {
    date: '2018-03-21',
    title: 'A new app helps alleviate migraine headache costs and pains',
    sourceName: 'Digital Commerce 360',
    url: 'https://www.digitalcommerce360.com/2018/03/21/a-new-app-helps-alleviate-migraine-headache-costs-and-pains/',
  },
]

export function listAllNews() {
  return newsItems
}

export default (
  listAllNews
)