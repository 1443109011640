import React, { Component } from 'react'

class Partners extends Component {
  render() {
    let atriumLogoPath

    if (this.props.device === 'desktop') {
      atriumLogoPath = process.env.PUBLIC_URL + `/logos/partners/atriumhealth-logo.jpg`
    } else {
      atriumLogoPath = process.env.PUBLIC_URL + `/logos/partners/atriumhealth-logo-mobile.jpg`
    }

    const headerBlockJsx = (
      <div className="div-no-image">
        <div className="header-text">
          <h3><span className="white">SensorRx</span> Partners</h3>
        </div>
      </div>
    )

    const partnersJsx = (
      <div className="page-wrapper partners">
        <div className="partner-logo">
          <a href="https://atriumhealth.org/">
            <img src={atriumLogoPath} alt="Atrium Health Logo" title="Atrium Health" className="rounded"></img>
          </a>
        </div>
      </div>
    )
      
    return (
      <div>
        {headerBlockJsx}
        {partnersJsx}
      </div>
    )
  }
}

export default Partners