export function formatFullName(firstName, lastName, postnomials) {
  if (postnomials) {
    return `${firstName} ${lastName}, ${postnomials}` 
  } else {
    return `${firstName} ${lastName}`
  }
}

export function formatPreviewTitles(srxTitles, outsideTitles, onTeam) {
  let formattedSrxTitles = []
  let formattedOutsideTitles = []
  let allTitles = []

  for (let i = 0; i < srxTitles.length; i++) {
    formattedSrxTitles.push(<h4>{srxTitles[i]}, <span className="text-strong">SensorRx</span></h4>)
  }

  for (let i = 0; i < outsideTitles.length; i++) {
    formattedOutsideTitles.push(<h4>{outsideTitles[i]}</h4>)
  }

  allTitles.push(<div className="profile-srx-titles">{formattedSrxTitles}</div>)
  allTitles.push(<div className="profile-outside-titles">{formattedOutsideTitles}</div>)

  return allTitles
}

export function formatProfileTitles(srxTitles, outsideTitles, onTeam) {
  let formattedSrxTitles = []
  let formattedOutsideTitles = []
  let allTitles = []

  for (let i = 0; i < srxTitles.length; i++) {
    formattedSrxTitles.push(<h4>{srxTitles[i]}, <span className="company">SensorRx</span></h4>)
  }

  for (let i = 0; i < outsideTitles.length; i++) {
    formattedOutsideTitles.push(<h4>{outsideTitles[i][0]}, <span className="company">{outsideTitles[i][1]}</span></h4>)
  }

  if (onTeam) {
    allTitles.push(<div className="profile-srx-titles">{formattedSrxTitles}</div>)
  }

  if (outsideTitles) {
    allTitles.push(<div className="profile-outside-titles">{formattedOutsideTitles}</div>)
  }

  return allTitles
}

export function formatProfileText(profileText) {
  let formattedProfileText = []

  for (let i = 0; i < profileText.length; i++) {
    formattedProfileText.push(<p>{profileText[i]}</p>)
  }

  return <div className="profile-text">{formattedProfileText}</div>
}

export function formatProductSummary(summaryText) {
  let formattedProductSummary = []

  for (let i = 0; i < summaryText.length; i++) {
    formattedProductSummary.push(<p>{summaryText[i]}</p>)
  }

  return <div className="profile-text">{formattedProductSummary}</div>
}
export function profilePhotoPath(id) {
  return process.env.PUBLIC_URL + `/profile-photos/${id}.jpg`
}

export function profilePhotoAlt(fullName) {
  return `Photo of ${fullName}`
}

export function productPreviewLogoPathDesktop(product) {
  return process.env.PUBLIC_URL + `/logos/product-previews/${product.id}-logo-block.png`
}

export function productPreviewLogoPathMobile(product) {
  return process.env.PUBLIC_URL + `/logos/product-previews/${product.id}-logo-block-small.png`
}

module.export = {
  formatFullName,
  formatProfileText,
  formatPreviewTitles,
  formatProductSummary,
  profilePhotoPath,
  profilePhotoAlt,
  productPreviewLogoPathDesktop,
  productPreviewLogoPathMobile
}