import React, { Component } from 'react'

// TODO: add links for email & phone number
class Contact extends Component {
  render() {
    let contactJsx

    if (this.props.device === 'desktop') {
      contactJsx = (
        <div className="page-wrapper">
          <div className="contact-grid">
            <div className="row-1-col-1">
              <h5 className="text-strong">Address:</h5>
            </div>
            <div className="row-1-col-2">
              <h5>408 North Main Street</h5>
              <h5>Davidson, NC 28036</h5>
            </div>

            <div className="row-2-col-1">
              <h5 className="text-strong">Email:</h5>
            </div>
            <div className="row-2-col-2">
              <h5>support@sensorrx.io</h5>
            </div>
            
            <div className="row-3-col-1">
              <h5 className="text-strong">Phone:</h5>
            </div>
            <div className="row-3-col-2">
              <h5>832.856.2178</h5>
            </div>
          </div>
        </div>
      )
    } else {
      contactJsx = (
        <div className="mobile-wrapper">
          <div className="contact-background">
            <h5 className="text-strong">Address:</h5>
            <h5>408 North Main Street</h5>
            <h5>Davidson, NC 28036</h5>
          </div>
          <div className="contact-background">
            <h5 className="text-strong">Email:</h5>
            <h5>support@sensorrx.io</h5>
          </div>
          <div className="contact-background">
            <h5 className="text-strong">Phone:</h5>
            <h5>832.856.2178</h5>
          </div>
        </div>
      )
    }
    return <div className="contact-page">{contactJsx}</div>
  }
}

export default Contact