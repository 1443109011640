const products = [
  {
    id: 'migrnx',
    name: 'MigrnX®',
    headline: 'MigrnX®: An Optimized Migraine Treatment',
    summary: ['Patients struggle to identify triggers and accurately count their number of migraines. Patients who use MigrnX® are at last provided a tool that allows for quick documentation of every migraine, and allows easy notation of symptoms, triggers, pain and medications. MigrnX creates a seamless connection to physicians by auto-forwarding all information from the app into the patient’s EHR. With this more accurate information, physicians can make better recommendations for preventative and acute medications.', 'In a study published in Cephalalgia, more than 40% of patients had their medication changed, and subsequently had a more than 70% reduced number of headaches.', 'MigrnX also uses your information from the app to build an algorithm specific to you. In other studies, these algorithms have been extremely accurate (PPV) > 70% and higher for HFEM and Chronic. The app sends you an alert approx. 12 hrs before an expected migraine, so you can take your medication on time, or make schedule changes. If you are on the correct regimen, and take the meds earlier enough, you may be able to completely skip having a migraine altogether.'],
    url: '',
    internalUrlPath: ''
  },
  {
    id: 'sicklepal',
    name: 'SicklePal',
    headline: 'SicklePal',
    summary: ['SicklePal was a clinical app developed for a research protocol for the extremely painful illness Sickle Cell Disease. The app was used in research to determine if better management of hydration could create better outcomes. The application was connected to providers so if patients experienced an increase in severity of any symptoms, they could quickly and easily connect through the app to an urgent care professional who could help patients manage the acute flare.'],
    url: '',
    internalUrlPath: ''
  },
  {
    id: 'lmgdfit',
    name: 'LMGDfit',
    headline: 'LMGDfit™ Pain Management System',
    summary: ['A research application for the management of the extremely debilitating form of muscular dystrophy called Limb Girdle. The app helped patients keep their physicians apprised of their physical activity, and in particular of any falls. Physicians were better able to understand disease progression by having the activity details automatically sent to the patients EHR.'],
    url: '',
    internalUrlPath: ''
  },
  {
    id: 'covidoncampus',
    name: 'Covid on Campus',
    headline: 'Covid on Campus Tracker',
    summary: ['Our Covid tracker was designed to help students and their healthcare providers keep track of student symptoms on a daily basis. The Covid trackers provided a protocol for students who showed symptoms to call a health professional immediately. This allowed colleges to continue sports, and also enabled them to have in person classes sooner. Our app was deployed to 6 colleges, and millions of symptom tracker questionnaires have been filled out since launch in the summer of 2020.'],
    url: '',
    internalUrlPath: ''
  },
  {
    id: 'migrainecoach',
    name: 'Migraine Coach',
    headline: 'Migraine Coach',
    summary: ['Migraine Coach is a popular consumer app used to track all the migraines a person gets in an easy, fast, minimalist format. There are reports a user can create and export as well as easily keep track of all medications, symptoms and triggers. Migraine Coach has thousands of users, some for more than 2 years, and regularly is included in the top 10 migraine digital diaries.'],
    url: 'http://migrainecoach.io/home',
    internalUrlPath: ''
  },
]

export function listAllProducts() {
  return products
}

export default (listAllProducts)