import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { productPreviewLogoPathDesktop, productPreviewLogoPathMobile, formatProductSummary } from '../helpers'

class ProductPreview extends Component {
  render() {
    const { name, headline, summary } = this.props.product
    const logoAltTitle = `${name} Logo`
    const productSummary = formatProductSummary(summary)

    let logoPath
    if (this.props.device === 'desktop') {
      logoPath = productPreviewLogoPathDesktop(this.props.product)
    } else {
      logoPath = productPreviewLogoPathMobile(this.props.product)
    }
    
    // let linkJsx
    // if (url) {
    //   linkJsx = <a href={url}>LEARN MORE</a>
    // } else if (internalUrlPath) {
    //   linkJsx = <Link to={internalUrlPath}>LEARN MORE</Link>
    // } else {
    //   linkJsx = ''
    // }

    const productPreviewJsx = (
      <Fragment>
        <div className="product-logo-container">
          <img src={logoPath} alt={logoAltTitle} title={logoAltTitle}></img>
        </div>
        <div className="preview-text">
          <p className="headline">{headline}</p>
          {productSummary}
          {/* <p><div className="link">{linkJsx}</div></p> */}
        </div>
      </Fragment>
    )
    
    return <div className="product-preview">{productPreviewJsx}</div>
  }
}

export default withRouter(ProductPreview)