import React, { Component, Fragment } from 'react'

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <h6>© SensorRx 2021</h6>
      </Fragment>
    )
  }
}

export default Footer