import React, { Component } from 'react'
import { withRouter } from 'react-router-dom' 
import { boardIndex } from './people'
import ProfilePreview from './ProfilePreview'

class BoardIndex extends Component {
  constructor(props) {
    super(props)

    this.state = {
      people: []
    }
  }

  componentDidMount() {
    this.setState({
      people: boardIndex()
    })
  }

  render() {
    const boardIndexJsx = this.state.people.map(
      person => <ProfilePreview key={person.id} person={person} returnLink={false}
    /> )
    
    return (
      <div className="page-wrapper">
        <div className="profile-index">{boardIndexJsx}</div>
      </div>
    )
  }
}

export default withRouter(BoardIndex)