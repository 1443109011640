import React, { Component } from 'react'
import { withRouter } from 'react-router-dom' 
import { advisorsIndex } from './people'
import ProfilePreview from './ProfilePreview'

class AdvisorsIndex extends Component {
  constructor(props) {
    super(props)

    this.state = {
      people: []
    }
  }

  componentDidMount() {
    this.setState({
      people: advisorsIndex()
    })
  }

  render() {
    const advisorsIndexJsx = this.state.people.map(
      person => <ProfilePreview key={person.id} person={person} returnLink={true}
    /> )
    
    return (
      <div className="page-wrapper">
        <div className="profile-index">{advisorsIndexJsx}</div>
      </div>
    )
  }
}

export default withRouter(AdvisorsIndex)