import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import Header from './Components/Header'
import Footer from './Components/Footer'
import About from './About/About'
import TeamIndex from './Profiles/TeamIndex'
import BoardIndex from './Profiles/BoardIndex'
import AdvisorsIndex from './Profiles/AdvisorsIndex'
import ProfileShow from './Profiles/ProfileShow'
import ProductIndex from './Products/ProductIndex'
import Partners from './Partners/Partners'
import Contact from './Contact/Contact'
import Home from './Home/Home'
import NewsIndex from './News/NewsIndex'

import background from './background.jpg'
import './App.scss'


function App() {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  
  let deviceClass, appBackground

  if (isTabletOrMobile) {
    deviceClass = "mobile"
    appBackground = {}
  } else {
    deviceClass = "desktop"
    appBackground = { 
      background: `url(${background}) center center / 100% 100% no-repeat`,
      backgroundAttachment: 'fixed',
    }
  }

  const classNames = `App ${deviceClass}`


  return (
    <Router>
      <div className={classNames}>
        <header >
          <Header device={deviceClass}/>
        </header>
        <main style={appBackground}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route exact path="/home" render={(props) => (
              <Home {...props} device={deviceClass} />
            )} />
            <Route exact path="/about" render={(props) => (
              <About {...props} device={deviceClass} />
            )} />
            <Route exact path="/news" component={NewsIndex} />
            <Route exact path="/team" component={TeamIndex} />
            <Route exact path="/board" component={BoardIndex} />
            <Route exact path="/scientific-advisors" component={AdvisorsIndex} />
            <Route exact path="/profiles/:id" render={(data) => (
              <ProfileShow id={data.match.params.id} />
            )} />
            <Route exact path="/products" render={(props) => (
              <ProductIndex {...props} device={deviceClass} /> 
            )} />
            <Route exact path="/partners" render={(props) => (
              <Partners {...props} device={deviceClass} />
            )} />
            <Route exact path="/contact" render={(props) => (
              <Contact {...props} device={deviceClass} /> 
            )} />
          </Switch>
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </Router>
  )
}

export default App;
