// NOTE: 'profileText' and 'outsideTitles' fields accept an array. If multiple parageraphs/lines are needed, add each paragraph as a separate item within the array.

const people = [
  {
    id: 'shannon-stearman',
    firstName: 'Shannon',
    lastName: 'Stearman',
    postnomials: '',
    isOnSrxTeam: true,
    isOnBoardOfDirectors: false,
    isScientificAdvisor: false,
    srxTitles: ['Co-Founder, Chief Strategy & Operations Officer'],
    outsideTitles: [],
    boardTitle: '',
    profileText: ['Shannon co-founded SensorRx in response to the overwhelming need to improve patient tools for people suffering from migraine and other chronic illnesses. Since she has battled migraine her entire adult life, she is intimately familiar with the gaps in care that chronic illness patients face.', 'Shannon was a Fellow at the Center for Theoretical Biological Physics at Rice University where she mentored young digital and healthcare entrepreneurs. She also developed international and domestic medical education programs for over 20 years for blockbuster medications including Relpax, Prozac, Neurontin, Zyprexa, Lipitor, Zometa, Gleevec, Viagra and others, all which achieved over a billion dollars in sales.', 'Since 1992 Shannon worked with or founded digital start-ups creating a variety of state-of-the-art solutions. Her UX mantra is minimalism and seamlessness. She has had successful exits and received a patent for her work in mobile media.', 'Shannon has done graduate work in biostatistics at the University of Texas Health Science Center in Houston, and published a paper in the field. Shannon holds a BA with Honors from Amherst College in Massachusetts.'],
  },
  {
    id: 'alex-dzeda',
    firstName: 'Alex',
    lastName: 'Dzeda',
    postnomials: '',
    isOnSrxTeam: true,
    isOnBoardOfDirectors: false,
    isScientificAdvisor: false,
    srxTitles: ['Co-Founder & Chief Technology Officer'],
    outsideTitles: [],
    boardTitle: '',
    profileText: ['Alex has been the technological backbone of SensorRx since its inception. He is responsible for all SensorRx back-end architecture and development including all verticals of application development, security protocol implementation, regulatory compliance, and integration with electronic health records and research platforms. Alex graduated from Rice University in Houston, Texas with a BS in Electrical and Computer Engineering. He was a Cain Fellow at the Shrine for Shriner’s Hospital. Alex’s senior Capstone Design Project was awarded Best Environment and Sustainability Engineering Design Award.'],
  },
  {
    id: 'george-mclendon',
    firstName: 'George',
    lastName: 'McLendon',
    postnomials: 'PhD',
    isOnSrxTeam: true,
    isOnBoardOfDirectors: true,
    isScientificAdvisor: false,
    srxTitles: ['Co-Founder, CEO & Board Chair'],
    boardTitle: 'Chair',
    outsideTitles: [['VP Therapeutics', 'Atrium Health'], ['Provost', 'Rice University'], ['Dean', 'Duke University']],
    profileText: ['George has been an Academic scientist and administrator for over three decades serving on multiple American and British faculties including Worcester Fellow at Oxford University, England, Chair of Chemistry at Princeton University, Dean of Duke University, and as Provost at Rice University in Texas.', 'George is also a serial entrepreneur in medical and digital solutions with multiple successful exits. He recently retired as Vice President of Research at Atrium Health where he was responsible for over 1000 clinical trials, including multiple successful longitudinal trials utilizing SensorRx’s MigrnX smartphone application. He has published over 200 peer-reviewed papers and holds multiple patents.'],
  },
  {
    id: 'josh-phipps',
    firstName: 'Josh',
    lastName: 'Phipps',
    postnomials: '',
    isOnSrxTeam: true,
    isOnBoardOfDirectors: false,
    isScientificAdvisor: false,
    srxTitles: ['Senior Data Scientist'],
    boardTitle: '',
    outsideTitles: [],
    profileText: ['Josh has been the SensorRx lead on analytics development including novel predictive systems for forecasting migraine utilizing neural networks. He has also created and deployed machine learning models for multiple SensorRx verticals, developing applications in iOS and Android. Josh graduated from Rice University in Houston, Texas with a BS in Electrical and Computer Engineering.'],
  },
  {
    id: 'nancy-chang',
    firstName: 'Nancy',
    lastName: 'Chang',
    postnomials: 'PhD',
    isOnSrxTeam: false,
    isOnBoardOfDirectors: true,
    isScientificAdvisor: false,
    srxTitles: [],
    boardTitle: 'Boardmember',
    outsideTitles: [['President', 'Apex Enterprises'], ['Founder & Chairwoman', 'Tanox, Inc.'], ['SuperAngel', 'SuperAngel.io']],
    profileText: [''],
  },
  {
    id: 't-jay-collins',
    firstName: 'T. Jay',
    lastName: 'Collins',
    postnomials: '',
    isOnSrxTeam: false,
    isOnBoardOfDirectors: true,
    isScientificAdvisor: false,
    srxTitles: [],
    boardTitle: 'Boardmember',
    outsideTitles: [['Director', 'GOOSE Society of Texas (Society of Entrepreneurial Investors)'], ['Chair', 'Oceaneering International'], ['Faculty', 'Rice University Jones Business School']],
    profileText: [''],
  },
  {
    id: 'robert-robbins',
    firstName: 'Robert C.',
    lastName: 'Robbins',
    postnomials: 'MD',
    isOnSrxTeam: false,
    isOnBoardOfDirectors: false,
    isScientificAdvisor: true,
    srxTitles: [],
    boardTitle: 'Scientific Advisor',
    outsideTitles: [['President', 'University of Arizona']],
    profileText: ['Former President and CEO of the Texas Medical Center (TMC) in Houston. While at the TMC, Dr. Robbins fostered an intense member institution collaboration, introducing five cross-institutional research initiatives centered on innovation, genomics, regenerative medicine, health policy and clinical research.', 'Dr. Robbins previously served as Chairman of the Department of Cardiothoracic Surgery at Stanford University School of Medicine and was the founding Director of the Stanford Cardiovascular Institute, among other roles.'],
  },
  {
    id: 'dawn-buse',
    firstName: 'Dawn C.',
    lastName: 'Buse',
    postnomials: 'PhD, FAHS',
    isOnSrxTeam: false,
    isOnBoardOfDirectors: false,
    isScientificAdvisor: true,
    srxTitles: [],
    boardTitle: 'Scientific Advisor',
    outsideTitles: [['Clinical Professor of Neurology', 'Albert Einstein College of Medicine'], ['Director of Behavioral Medicine', 'Montefiore Headache Center, New York, NY'], ['Board Member & Fellow', 'The American Headache Society']],
    profileText: ['Dr. Buse has authored or edited over 400 hundred manuscripts, reviews and abstracts, and has been the recipient of many National and International awards including the American Headache Society’s “Wolff Award” twice and the Italian Society for the Study of Headache’s “Enrico Greppi Award” twice. She is a leader in behavioral health related to migraine and pain utilizing mindful interventions and is the editor to multiple peer-reviewed journals on headache, pain, and behavior.'],
  },  
  {
    id: 'richard-lipton',
    firstName: 'Richard B.',
    lastName: 'Lipton',
    postnomials: 'MD, FAHS',
    isOnSrxTeam: false,
    isOnBoardOfDirectors: false,
    isScientificAdvisor: true,
    srxTitles: [],
    boardTitle: 'Scientific Advisor',
    outsideTitles: [['Director', 'Montefiore Headache Center'], ['The Edwin S. Lowe Professor and Vice Chair of Neurology, Professor of Epidemiology and Population Health, and Professor of Psychiatry and Behavioral Sciences', 'Albert Einstein College of Medicine.']],
    profileText: ['Dr. Lipton is a Past President of the American Headache Society. He also received the 2018 Harold G. Wolf Lecture Award for his research on the (CaMEO) Study. This is Dr. Lipton’s 5th time receiving the prestigious award. He serves on the editorial boards for several journals, has published 11 books and has more than 800 PubMed indexed articles.'],
  },
  {
    id: 'russ-bodner',
    firstName: 'Russ A.',
    lastName: 'Bodner',
    postnomials: 'MD',
    isOnSrxTeam: false,
    isOnBoardOfDirectors: false,
    isScientificAdvisor: true,
    srxTitles: [],
    boardTitle: 'Scientific Advisor',
    outsideTitles: [['Clinical Neurophysiologist', 'Atrium Health']],
    profileText: ['Practicing Clinical Neurophysiologist for over 20 years at Atrium Health; Lead Neurologist on multiple SensorRx MigrnX clinical trials and in the facilitation of adopting the MigrnX tool. Dr. Bodner also co-developed the migraine clinical guidelines for the Emergency and Neurology Care settings at Atrium. Dr. Bodner is consistently ranked as a Top Doctor in US News & World Report and receives excellent patient satisfaction scores.'],
  },
  {
    id: 'liz-cramer-fox',
    firstName: 'Liz',
    lastName: 'Cramer Fox',
    postnomials: '',
    isOnSrxTeam: true,
    isOnBoardOfDirectors: false,
    isScientificAdvisor: false,
    srxTitles: ['Front-End Web Engineer'],
    boardTitle: '',
    outsideTitles: [],
    profileText: [],
  },
]


// Export Functions
export function profileShow(id) {
  return people.find(person => person.id === id)
}

export function teamIndex() {
  return people.filter(person => person.isOnSrxTeam)
}

export function boardIndex() {
  return people.filter(person => person.isOnBoardOfDirectors)
}

export function advisorsIndex() {
  return people.filter(person => person.isScientificAdvisor)
}

export default (
  profileShow,
  teamIndex,
  boardIndex,
  advisorsIndex
)