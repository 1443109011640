import React, { Component } from 'react'

class NewsPreview extends Component {
  render() {
    const { date, title, sourceName, url } = this.props.newsItem
    
    const newsPreviewJsx = (
      <a href={url}>
        <h3>{title}</h3>
        <h5>{sourceName}</h5>
        <h6>Published: {date}</h6>
      </a>
    )
    
    
    return <div className="news-item">{newsPreviewJsx}</div>
  }
}

export default NewsPreview